import React from "react";
import Box from "@mui/material/Box";
import Menu from "../menu";
import { makeStyles } from "@mui/styles";
import logo from "../../assets/img/taxia-life-logo-white.png";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { logout } from "../../middleware/auth";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    [theme.breakpoints.up("md")]: {
      height: "100%",
    },
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
  },
  containerImg: {
    filter: " blur(0px) !important",
    marginRight: "auto",
    marginLeft: "auto",
  },
  toolbar: {
    [theme.breakpoints.down("xs")]: {
      minHeight: "64px !important",
    },
  },
  logo: {
    height: "100%",
  },
}));

const Header = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const closeSesion = () => {
    logout();
  };
  return (
    <Box className={classes.header}>
      <Box
        className={classes.containerImg}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: { xs: 130, sm: 130, md: 130, lg: 200, xl: 200 },
        }}
      >
        <img src={logo} className={classes.logo} alt="taxia-logo" />
      </Box>
      <Box
        className={classes.containerImg}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: { xs: 130, sm: 130, md: 130, lg: 200, xl: 200 },
        }}
      >
        <Menu closeSesion={closeSesion} />
      </Box>
      {useMediaQuery(theme.breakpoints.up("md")) ? (
        <Box
          sx={{
            margin: "10px 10px 30px 10px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
          }}
        >
          <Button
            type="submit"
            variant="text"
            color="secondary"
            fullWidth
            onClick={closeSesion}
          >
            {t("user.closeSession")}
          </Button>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};

export default Header;
