import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import "./translations/i18n";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/700.css";
import reportWebVitals from "./reportWebVitals";
import CustomThemeProvider from "./themes/CustomThemeProvider";
import "mapbox-gl/dist/mapbox-gl.css";

<link rel="preconnect" href="https://fonts.googleapis.com" />;
<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />;
<link
  rel="stylesheet"
  href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap"
/>;
<link
  href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap"
  rel="stylesheet"
></link>;
<link
  href="https://api.mapbox.com/mapbox-gl-js/v3.1.2/mapbox-gl.css"
  rel="stylesheet"
/>;
<meta name="viewport" content="initial-scale=1, width=device-width" />;

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <CustomThemeProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </CustomThemeProvider>
);

reportWebVitals();
