import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../assets/img/taxia-life-logo-white.png";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { isValidPhoneNumber } from "libphonenumber-js";

import User from "../../api/user";
import CustomDialog from "../common/customDialog";

import Grid from "@mui/material/Grid";
import Box from "@mui/system/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Spinner } from "react-bootstrap";

import "react-phone-input-2/lib/style.css";
import CustomPhoneInput from "../common/customPhoneInput";

const useStyles = makeStyles((theme) => ({
  root: { position: "absolute", top: 0, bottom: 0 },
  paper: {
    margin: theme.spacing(4, 4),
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
  },
  form: {
    marginTop: theme.spacing(1),
    // height: "450px",
  },
  logo: {
    width: "25vw",
  },
  PhoneInput: {
    backgroundColor: "red",
  },
}));

const register = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState({
    state: false,
    description: "",
    type: "success",
  });
  const classes = useStyles();
  const theme = useTheme();
  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const validPhone = isValidPhoneNumber(phone);
    if (!validPhone) {
      setMessage({
        state: true,
        description: "Ingrese un telefono valido",
        type: "error",
      });
      handleOpenSnackbar();
      return;
    }
    setLoading(true);
    const response = await User.create({ email, password, name, phone });
    if (response?.data?.token) {
      setMessage({
        state: true,
        description: "Los datos fueron ingresados correctamente!",
        type: "success",
      });
      handleOpenSnackbar();
      setTimeout(() => {
        history.push({
          pathname: "/login",
        });
      }, 1000);
    } else if (response?.errors) {
      setMessage({
        state: true,
        description: response.message,
        type: "error",
      });
      handleOpenSnackbar();
    } else {
      setMessage({
        state: true,
        description: "Hubo un error en el servidor!",
        type: "error",
      });
      handleOpenSnackbar();
    }
    setLoading(false);
  };

  const handleOpenSnackbar = () => {
    setOpen(true);
    setTimeout(() => {
      handleCloseSnackbar();
    }, 3000);
  };

  const handleCloseSnackbar = () => {
    setOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Grid container component="main" className={classes.root}>
        <Grid
          item
          sm={12}
          md={4}
          sx={{
            backgroundColor: theme.palette.primary.main,
            height: { xs: "15%", sm: "15%", md: "100%", lg: "100%" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          component="section"
        >
          <img src={logo} className={classes.logo} alt="taxia-logo" />
        </Grid>
        <Grid
          component="section"
          item
          xs={12}
          sm={12}
          md={8}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box className={classes.paper}>
            <Typography
              variant="h2"
              sx={{
                color: "black",
                fontWeight: "500",
                fontSize: "42px",
              }}
            >
              {t("register.header")}
            </Typography>
            <br />
            <Tabs
              textColor="primary"
              indicatorColor="primary"
              value={tabValue}
              onChange={handleTabChange}
            >
              <Tab value={0} label={t("register.tabData")} />
              {/* <Tab value={1} label={t("register.tabPhone")} /> */}
            </Tabs>
            {tabValue === 0 && (
              <form className={classes.form} onSubmit={handleSubmit}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  type="email"
                  label={t("register.email")}
                  name="email"
                  autoComplete="email"
                  variant="filled"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{ disableUnderline: true }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={t("register.password")}
                  type="password"
                  id="password"
                  variant="filled"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{ disableUnderline: true }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label={t("register.name")}
                  name="name"
                  autoComplete="name"
                  variant="filled"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  InputProps={{ disableUnderline: true }}
                />
                <br />
                {/* <InputLabel htmlFor="verify-phone">
                    {t("register.phone")}
                  </InputLabel> */}
                <CustomPhoneInput
                  required
                  id="register-phone"
                  name="phone"
                  onChange={(e) => setPhone(`+${e}`)}
                  value={phone}
                  label={t("register.phone")}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{
                        marginRight: "10px",
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {t("register.button")}
                </Button>
              </form>
            )}
            {/* {
              tabValue === 1 && (
                <form className={classes.form} onSubmit={handleSubmit}>
                  <InputLabel htmlFor="verify-phone">
                    {t("register.phone")}
                  </InputLabel>
                  <CustomPhoneInput
                    required
                    id="verify-phone"
                    name="phone"
                    onChange={(e) => setPhone(`+${e}`)}
                    value={phone}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    {t("register.buttonVerify")}
                  </Button>
                </form>
              )
            } */}
            <Grid
              container
              direction="column"
              justifyContent="space-around"
              mt={5}
              mb={0}
            >
              <Grid item>
                <Link
                  onClick={() =>
                    history.push({
                      pathname: `/login`,
                    })
                  }
                  variant="subtitle1"
                  underline="none"
                >
                  {t("register.haveAccount")}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Typography
                style={{
                  color: theme.palette.primary.main,
                }}
              >
                {"Copyright © "}
                <Link href="https://taxialife.com/" underline="hover">
                  Taxia Life {new Date().getFullYear()}
                  {"."}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <CustomDialog
        onClose={handleCloseSnackbar}
        open={open}
        variant="snackbar"
        severity={message.type}
      >
        {message.description}
      </CustomDialog>
    </>
  );
};

export default register;
