import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: ["roboto", "cursive"].join(","),
  },
  palette: {
    primary: {
      main: "#3a2b80",
      light: "#A1AFB5",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#FFB74D",
      light: "A1AFB5",
      dark: "rgb(200, 147, 89)",
      contrastText: "#FAFAFA",
    },
    background: {
      default: "#ffffff",
    },
    titleBar: {
      main: "#ffffff",
      contrastText: "#555555",
    },
    inputs: {
      main: "#000000",
    },
    inputBorder: {
      main: "#A1AFB5",
    },
    inputText: {
      main: "#000000",
    },
    error: {
      main: "#B80017",
    },
    cardmap: {
      main: "#ffffff",
    },
    avatar: {
      main: "#bdbdbd",
    },
    loginHeader: {
      main: "#000000",
    },
    text1: {
      main: "#000000",
    },
    requestDialog: {
      main: "#ffffff",
    },
  },
});

export default theme;
