import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

import Button from "@mui/material/Button";
import CustomDialog from "../common/customDialog";
import Box from "@mui/system/Box";
import Typography from "@mui/material/Typography";

export default function cancelService(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCancel = (e) => {
    e.preventDefault();
    props.cancel();
    setShow(false);
  };

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={handleShow}
        fullWidth
        style={{
          marginTop: "0px",
          boxShadow: 18,
        }}
      >
        {t("assigned.cancel")}
      </Button>
      <CustomDialog
        onClose={handleClose}
        open={show}
        variant="full-dialog"
        title={t("assigned.cancel")}
      >
        <Box
          sx={{
            margin: "0px",
            padding: "0px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.black.main,
              fontWeight: "500",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            {t("assigned.cancelHeader")}
          </Typography>
        </Box>
        <Box mt={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Button color="primary" variant="contained" onClick={handleCancel}>
            {t("assigned.cancelService")}
          </Button>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            {t("assigned.continue")}
          </Button>
        </Box>
      </CustomDialog>
    </>
  );
}
