/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import mapboxgl from "!mapbox-gl";
import polyline from "@mapbox/polyline";

import Card from "@mui/material/Card";
import AdjustIcon from "@mui/icons-material/Adjust";
import RoomIcon from "@mui/icons-material/Room";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import InputBase from "@mui/material/InputBase";
import Autocomplete from "@mui/lab/Autocomplete";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Address from "../../api/address";
import RequestTaxi from "./requestTaxi";
import RouteCard from "./routeCard";
import Service from "../../api/service";
import Header from "../shared/header";
import Box from "@mui/material/Box";

import InputAdornment from "@mui/material/InputAdornment";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomDialog from "../common/customDialog";
const { REACT_APP_MAPBOX_TOKEN } = process.env;

const StyleBackdrop = makeStyles((theme) => ({
  backdrop: {
    margin: 0,
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    bottom: 0,
  },
  lateral: {
    [theme.breakpoints.down("md")]: {
      height: "125px",
    },
    [theme.breakpoints.up("md")]: {
      height: "100%",
    },
  },
  input: {
    "& .MuiInputAdornment-positionStart": {
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "12px !important",
    },
  },
  destinationButton: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "5px",
    },
    [theme.breakpoints.up("md")]: {
      marginRight: "5px",
    },
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      fontSize: "0.725rem !important",
    },
  },
  cardMap: {
    zIndex: "2",
    position: "absolute",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.up("md")]: {
      width: "60%",
    },
  },
  bottomzone: {
    zIndex: "2",
    position: "absolute",
    alignItems: "center",
    justifyItems: "center",
    justifyContent: "center",
    alignContent: " center",
    bottom: "10px",
    maxWidth: "90%",
  },
}));

mapboxgl.accessToken = REACT_APP_MAPBOX_TOKEN;

const request = (props) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-75.48);
  const [lat, setLat] = useState(5.06);
  const [zoom, setZoom] = useState(9);
  const [destinationMarker, setDestinationMarker] = useState(null);
  const [userMarker, setUserMarker] = useState(null);
  const [geolocate, setGeolocate] = useState(null);
  const [markerFlag, setMarkerFlag] = useState(false);
  const [open, setOpen] = useState(false);
  const styleBackdrop = StyleBackdrop();
  const [init, setInit] = useState("");
  const [city, setCity] = useState(0);
  const [final, setFinal] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [destination, setDestination] = useState(null);
  const [enableDestination, setEnableDestination] = useState(false);
  const [routeCard, setRouteCard] = useState(false);
  const [timer, setTimer] = useState(null);
  const [route, setRoute] = useState(null);
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { t } = useTranslation();
  const markerRef = useRef();
  markerRef.current = destinationMarker;
  const stateRef = useRef();
  stateRef.current = markerFlag;
  const userRef = useRef();
  userRef.current = userMarker;
  const [message, setMessage] = useState({
    state: false,
    description: "",
    type: "success",
  });

  useEffect(() => {
    localStorage.removeItem("serviceData");
    localStorage.removeItem("props");
    localStorage.removeItem("route");
    localStorage.removeItem("service");

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom,
    });

    initDestinationMarker();

    const el = document.createElement("div");
    el.className = "marker";
    const userMarker = new mapboxgl.Marker(el)
      .setLngLat([0, 0])
      .addTo(map.current)
      .setDraggable(true)
      .setPopup(
        new mapboxgl.Popup({
          offset: 25,
          closeButton: false,
          closeOnClick: false,
        }).setText(t("service.you"))
      );

    userMarker.on("dragend", (e) => {
      const coords = userMarker.getLngLat();
      const { lat: newLat, lng: newLng } = coords;
      setLat(newLat);
      setLng(newLng);
      setAddressPosition(newLat, newLng);
      if (stateRef.current) {
        setEnableDestination(false);
        removeDestinationMarker();
      }
    });

    if (window.navigator && window.navigator.geolocation) {
      getLocationPromise().then((res) => {
        const { coords } = res;
        userMarker.setLngLat([coords.longitude, coords.latitude]);
        map.current.flyTo({
          center: [coords.longitude, coords.latitude],
          zoom: 17,
        });
        setLat(coords.latitude);
        setLng(coords.longitude);
        setAddressPosition(coords.latitude, coords.longitude);
      });
    }

    map.current.addControl(new mapboxgl.NavigationControl(), "bottom-right");
    const geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
    });
    setGeolocate(geolocate);
    map.current.addControl(geolocate, "bottom-right");
    geolocate.on("geolocate", (e) => {
      const { longitude, latitude } = e.coords;
      setLat(latitude);
      setLng(longitude);
      userMarker.setLngLat([longitude, latitude]);
      setAddressPosition(latitude, longitude);
    });
    setUserMarker(userMarker);
    return () => map.current.remove();
  }, []);

  useEffect(() => {
    setDestination(null);
  }, [init]);

  const removeDestinationMarker = () => {
    setMarkerFlag(false);
    markerRef.current.remove();
    removeRoute();
    const { lng: userLng, lat: userLat } = userRef.current.getLngLat();
    map.current.flyTo({
      center: [userLng, userLat],
      zoom: 15,
    });
  };

  const removeRoute = () => {
    setDestination(null);
    setRouteCard(false);
    setRoute(null);
    setFinal("");
    localStorage.removeItem("route");
    if (map.current.getLayer("route")) map.current.removeLayer("route");
    if (map.current.getSource("route")) map.current.removeSource("route");
  };

  const getAddressSearch = async (address, input) => {
    if (address.length > 7) {
      setLoading(true);
      const response = await Address.search(
        city.geocode_id,
        `${address} ${city.name
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")}`
      );
      if (input === "userAddress") {
        setLat(response[0].latitude);
        setLng(response[0].longitude);
        userMarker.setLngLat([response[0].longitude, response[0].latitude]);
        setInit(response[0].address);
      }
      if (input === "destinationAddress") {
        setAddresses(response);
      }
      setLoading(false);
    }
  };

  const drawRoute = (coordinates) => {
    if (map.current.getLayer("route")) map.current.removeLayer("route");
    if (map.current.getSource("route")) map.current.removeSource("route");
    map.current.addSource("route", {
      type: "geojson",
      data: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: coordinates,
        },
      },
    });
    map.current.addLayer({
      id: "route",
      type: "line",
      source: "route",
      layout: {
        "line-join": "round",
        "line-cap": "round",
        "line-round-limit": 0.5,
      },
      paint: {
        "line-color": "#6738D1",
        "line-width": 10,
      },
    });

    setRouteCard(true);
  };

  const decodeRoute = (route) => {
    const flippedLngLat = polyline
      .decode(route.route)
      .map(([lat, lng]) => [lng, lat]);
    drawRoute(flippedLngLat);
  };

  const getRoute = async (originLng, originLat, destLng, destLat) => {
    const route = await Service.route(
      `${originLat},${originLng}`,
      `${destLat},${destLng}`
    );
    setRoute(route);
    localStorage.setItem("route", JSON.stringify(route));
    decodeRoute(route);
  };

  const addDestinationMarker = async (destLat, destLng) => {
    setMarkerFlag(true);
    destinationMarker
      .setLngLat([destLng, destLat])
      .addTo(map.current)
      .setDraggable(true)
      .setPopup(
        new mapboxgl.Popup({
          offset: 25,
          closeButton: false,
          closeOnClick: false,
        }).setText(t("services.yourDestination"))
      );
    const { lng: userLng, lat: userLat } = userRef.current.getLngLat();
    getRoute(userLng, userLat, destLng, destLat);
    destinationMarker.on("dragend", async (e) => {
      const coords = destinationMarker.getLngLat();
      const { lat: newLat, lng: newLng } = coords;
      setLoading(true);
      const response = await Address.getAddress(
        city.geocode_id,
        newLat,
        newLng
      );
      response.latitude = newLat;
      response.longitude = newLng;
      if (response?.address) {
        setDestination(response);
        setFinal(response.address);
      }
      getRoute(userLng, userLat, newLng, newLat);
      setLoading(false);
    });
    map.current.fitBounds(
      [
        [lng, lat],
        [destLng, destLat],
      ],
      {
        padding: { top: 250, bottom: 100, left: 100, right: 100 },
      }
    );
  };

  const toggleDestination = () => {
    if (enableDestination) removeDestinationMarker();
    if (routeCard) setRouteCard(false);
    setEnableDestination(!enableDestination);
  };

  const handleClose = (event) => {
    setMessage({
      state: false,
      description: "",
      type: "info",
    });
  };

  const getLocationPromise = () => {
    return new Promise(function (resolve, reject) {
      window.navigator?.geolocation?.getCurrentPosition(
        (position) => resolve(position),
        (error) => reject(error)
      );
    });
  };

  const getCity = async (latitude, longitude) => {
    const response = await Address.domain(latitude, longitude);
    if (response?.setting) {
      setCity(response);
      return response.geocode_id;
    }
    if (response?.errors) {
      setMessage({
        state: true,
        description: response.message,
        type: "warning",
      });
      setTimeout(() => {
        setLoading(false);
        handleClose();
      }, 2000);
    }
  };
  const setAddressPosition = async (latitude, longitude) => {
    const cityId = await getCity(latitude, longitude);
    const response = await Address.getAddress(cityId, latitude, longitude);
    if (response?.address) {
      setInit(response.address);
    }
  };
  const initDestinationMarker = () => {
    const iconDestination = document.createElement("div");
    iconDestination.className = "markerDestination";
    const destinationMarker = new mapboxgl.Marker(iconDestination);
    setDestinationMarker(destinationMarker);
  };

  const request = async (data) => {
    setOpen(true);
    data.latitude = lat;
    data.longitude = lng;
    data.address = init;
    if (destination) {
      data.destination = destination;
      data.destination.address = final;
      delete destination.city_id;
      delete destination.hint;
      delete destination.neightborhood;
    }
    data.payment_method = "CASH";
    const service = await Service.getService(data);
    if (service?.message) {
      if (service?.message === "jwt expired") {
        localStorage.removeItem("auth");
        localStorage.removeItem("user-taxia");
        localStorage.removeItem("service");
        setMessage({
          state: true,
          description: "Por favor, vuelve a iniciar sesión",
          type: "error",
        });
        history.push({
          pathname: "/login",
        });
      }
      setOpen(false);
      setMessage({
        state: true,
        description: `${service.message}`,
        type: "error",
      });
      return;
    }
    setOpen(false);
    if (service.data.id) {
      history.push({
        pathname: "/assigned",
        service: service.data,
        lat: data.latitude,
        long: data.longitude,
      });
    }
  };

  return (
    <Grid container className={classes.root} rowSpacing={0} columnSpacing={0}>
      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        lg={2}
        xl={2}
        sx={{
          backgroundColor: theme.palette.primary.main,
        }}
        className={classes.lateral}
        component="section"
      >
        <Header />
      </Grid>
      <Grid
        component="section"
        item
        xs={12}
        sm={12}
        md={9}
        lg={10}
        xl={10}
        sx={{
          position: "relative",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          justifyContent: "center",
          height: {
            xs: "85%",
            sm: "85%",
            md: "100%",
          },
        }}
        container
      >
        <Box
          ref={mapContainer}
          sx={{
            position: "relative",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            width: "100%",
          }}
        />
        <Card className={classes.cardMap} elevation={12}>
          <Container
            style={{ marginTop: "10px", marginBottom: "10px" }}
            spacing={0}
          >
            <Grid container spacing={1} style={{ padding: "0px" }}>
              <Grid
                container
                item
                xs={1}
                md={1}
                lg={1}
                sx={{
                  marginRight: "0.15rem",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <AdjustIcon color="primary" fontSize="large" />
              </Grid>
              <Grid container item xs={10} md={10} lg={10}>
                <InputBase
                  id="userAddress"
                  className={classes.input}
                  placeholder={t("service.pickup")}
                  inputProps={{
                    "aria-label": "Pickup Location",
                  }}
                  startAdornment={
                    <InputAdornment disableTypography position="start">
                      {t("service.address")}
                    </InputAdornment>
                  }
                  value={init}
                  fullWidth
                  type="text"
                  variant="custom-card-input"
                  onChange={(e) => {
                    if (e.target.value === "") {
                      removeDestinationMarker();
                      geolocate.trigger();
                    }
                    clearTimeout(timer);
                    const newTimer = setTimeout(() => {
                      getAddressSearch(e.target.value, e.target.id);
                    }, 1000);
                    setTimer(newTimer);
                    setInit(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              style={{ padding: "0px", marginTop: "1px" }}
            >
              <Grid
                container
                item
                xs={1}
                md={1}
                lg={1}
                sx={{
                  marginRight: "0.15rem",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <RoomIcon color="primary" fontSize="large" />
              </Grid>
              <Grid
                container
                item
                xs={10}
                md={10}
                lg={10}
                sx={{
                  marginRight: "0.15rem",
                  display: "flex",
                  alignContent: "center",
                  flexWrap: "nowrap",
                  [theme.breakpoints.down("md")]: {
                    flexWrap: "wrap",
                  },
                }}
              >
                <Button
                  className={classes.destinationButton}
                  variant="text"
                  color="primary"
                  onClick={toggleDestination}
                  fullWidth={
                    useMediaQuery(theme.breakpoints.down("md")) ||
                    !enableDestination
                  }
                  sx={{
                    minWidth: "max-content",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "12px !important",
                    },
                    [theme.breakpoints.down("lg")]: {
                      marginBottom: "2px",
                    },
                  }}
                >
                  {t("service.destinationOptional")}
                </Button>
                {enableDestination ? (
                  <Autocomplete
                    id="destinationAddress"
                    options={addresses}
                    filterOptions={(x) => x}
                    loading={loading}
                    value={final}
                    autoComplete
                    noOptionsText={t("service.nooptions")}
                    className={classes.input}
                    fullWidth
                    sx={{ marginLeft: "5px" }}
                    onChange={(event, newValue) => {
                      if (newValue && newValue.address) {
                        setFinal(newValue.address);
                        addDestinationMarker(
                          newValue.latitude,
                          newValue.longitude
                        );
                        setDestination(newValue);
                      } else {
                        setDestination(null);
                        setRouteCard(false);
                        removeDestinationMarker();
                        setFinal("");
                      }
                    }}
                    getOptionLabel={(option) => {
                      if (option && option.address) {
                        return option.address;
                      }
                      return "";
                    }}
                    getOptionSelected={(option, value) => {
                      if (option.address && value) {
                        return (
                          option.address.toUpperCase() === value.toUpperCase()
                        );
                      } else {
                        return true;
                      }
                    }}
                    renderInput={(params) => (
                      <div ref={params.InputProps.ref}>
                        <InputBase
                          {...params.inputProps}
                          onChange={(e) => {
                            if (e.target.value === "") {
                              setDestination(null);
                              setRouteCard(false);
                              removeDestinationMarker();
                            }
                            setFinal(e.target.value);
                            clearTimeout(timer);
                            const newTimer = setTimeout(() => {
                              getAddressSearch(e.target.value, e.target.id);
                            }, 1000);
                            setTimer(newTimer);
                          }}
                          startAdornment={
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress
                                  color="inherit"
                                  size={20}
                                  thickness={5}
                                  sx={{ marginRight: "7px" }}
                                />
                              ) : (
                                <Box mx={2} />
                              )}
                              {params.InputProps.startAdornment}
                            </React.Fragment>
                          }
                          style={{
                            marginLeft: "0px",
                            fontSize: "13px",
                            width: "100%",
                            margin: "0px",
                          }}
                          value={final}
                          placeholder={t("service.destination")}
                          className={classes.input}
                          fullWidth
                          variant="custom-card-input"
                        />
                      </div>
                    )}
                  />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Container>
        </Card>
        <Backdrop className={styleBackdrop.backdrop} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container className={classes.bottomzone} spacing={2}>
          {routeCard ? (
            <Grid item xs={12} sm={7} md={6} lg={5} xl={4}>
              <RouteCard route={route}></RouteCard>
            </Grid>
          ) : (
            ""
          )}
          <Grid item xs={12} sm={5} md={12} lg={6} xl={6}>
            <RequestTaxi request={request} />
          </Grid>
        </Grid>
      </Grid>
      <CustomDialog
        onClose={handleClose}
        open={message.state}
        variant="snackbar"
        severity={message.type}
      >
        {message.description}
      </CustomDialog>
    </Grid>
  );
};
export default request;
