import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import logo from "../../assets/img/taxia-life-logo-white.png";

import User from "../../api/user";
import { isLogin } from "../../middleware/auth";
import CustomDialog from "../common/customDialog";

import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";

// makestyles, being a legacy element, will only be used
// for configurations related to responsive breakpoints,
// positions, spacing and dimensions of the elements
const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    bottom: 0,
  },
  paper: {
    margin: theme.spacing(4, 4),
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
  },
  form: {
    marginTop: theme.spacing(1),
  },
  logo: {
    width: "25vw",
  },
}));

const login = (props) => {
  const history = useHistory();
  if (isLogin()) {
    return (window.location.href = "/");
  }
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    state: false,
    description: "",
    type: "success",
  });
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    localStorage.removeItem("auth");
    localStorage.removeItem("user-taxia");
    localStorage.removeItem("service");
  });

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setLoading(true);
    const response = await User.login({ email, password });
    if (response?.token) {
      setMessage({
        state: true,
        description: t("login.success"),
        type: "success",
      });
      setTimeout(() => {
        localStorage.setItem("auth", response.token);
        localStorage.setItem("user-taxia", JSON.stringify(response));
        window.location.href = "/";
      }, 1000);
    } else {
      setMessage({
        state: true,
        description: t("login.error"),
        type: "error",
      });
    }
    handleOpen();
  };

  const handleOpen = () => {
    setOpen(true);
    setTimeout(() => {
      setLoading(false);
      handleClose();
    }, 1000);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container component="main" className={classes.root}>
      <Grid
        item
        sm={12}
        md={4}
        sx={{
          backgroundColor: theme.palette.primary.main,
          height: { xs: "15%", sm: "15%", md: "100%", lg: "100%" },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        component="section"
      >
        <img src={logo} className={classes.logo} alt="taxia-logo" />
      </Grid>
      <Grid
        component="section"
        item
        xs={12}
        sm={12}
        md={8}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box className={classes.paper}>
          <Typography
            variant="h2"
            sx={{
              color: theme.palette.black.main,
              fontWeight: "500",
              fontSize: "42px",
            }}
          >
            {t("login.header")}
          </Typography>
          <Typography
            variant="h3"
            sx={{
              color: theme.palette.black.main,
              fontWeight: "400",
              fontSize: "16px",
              marginTop: "30px",
            }}
          >
            {t("login.subHeader")}
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              margin="normal"
              required
              type="email"
              fullWidth
              id="email"
              label={t("login.email")}
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="filled"
              autoComplete="email"
              InputProps={{ disableUnderline: true }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t("login.password")}
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="filled"
              InputProps={{ disableUnderline: true }}
            />
            <Button type="submit" fullWidth variant="contained" color="primary">
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{
                    marginRight: "10px",
                  }}
                />
              ) : (
                ""
              )}
              {t("login.button")}
            </Button>
            <CustomDialog
              onClose={handleClose}
              open={open}
              variant="snackbar"
              severity={message.type}
            >
              {message.description}
            </CustomDialog>
            <Grid
              container
              direction="column"
              justifyContent="space-around"
              mt={2}
            >
              <Grid item>
                <Link
                  onClick={() =>
                    history.push({
                      pathname: "/recovery",
                    })
                  }
                  variant="subtitle1"
                  underline="none"
                >
                  {t("login.rememberPassword")}
                </Link>
              </Grid>
              <Grid item>
                <Link
                  onClick={() =>
                    history.push({
                      pathname: "/register",
                    })
                  }
                  variant="subtitle1"
                  underline="none"
                >
                  {t("login.account")}
                </Link>
              </Grid>
            </Grid>
          </form>
          <Box mt={17}>
            <Typography
              style={{
                color: theme.palette.primary.main,
              }}
            >
              {"Copyright© "}
              <Link href="https://taxialife.com/" underline="hover">
                Taxia Life {new Date().getFullYear()}
                {"."}
              </Link>
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default login;
