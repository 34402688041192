import Base from "./base";

async function getService(data) {
  const response = await Base.post("/service", data);
  return response;
}

async function verify(id) {
  const response = await Base.get(`/service/verify/${id}`, "taxia");
  return response.data;
}

async function cancel(data) {
  const response = await Base.post("/service/cancel", data);
  return response.data;
}

async function route(origin, destination) {
  const response = await Base.get(
    `/offer?origin=${origin}&destination=${destination}`,
    "core"
  );
  return response.data;
}

async function getURL(id) {
  const response = await Base.get(`/share/${id}`, "user");
  return response;
}

export default {
  getService,
  verify,
  cancel,
  route,
  getURL,
};
