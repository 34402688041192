import axios from "axios";

const HEADERS = {};
const HEADERSCORE = {};
const HEADERSAPIUSER = {};
const {
  REACT_APP_SERVER,
  REACT_APP_API_GEO,
  REACT_APP_API_TAXIA,
  REACT_APP_API_USER,
  REACT_APP_X_TAXIA_ACCESS,
  REACT_APP_API_CORE,
  REACT_APP_NODE_ENV,
} = process.env;

let Authorization;

export const getToken = (token) => {
  Authorization = `Bearer ${token}`;
  HEADERS.Authorization = Authorization;
};

if (localStorage.getItem("auth")) {
  Authorization = `Bearer ${localStorage.getItem("auth")}`;
  HEADERS.Authorization = Authorization;
}

HEADERS["Content-Type"] = "application/json";
HEADERS["x-taxia-access"] = `${REACT_APP_X_TAXIA_ACCESS}`;
HEADERSCORE["Content-Type"] = "application/json";
HEADERSCORE["x-citytaxi-access-key"] =
  REACT_APP_NODE_ENV === "development"
    ? "dfTg6340hNmferSdxm80$1jalKufd1rg$53&2wdplje"
    : "FXs@e4r$kLmn8l38oEdLj1sFxC2sZxv3d3mVi78SsZ0";
HEADERSAPIUSER["Content-Type"] = "application/json";
HEADERSAPIUSER["x-citytaxi-master-key"] = "citytaxi-secret";
HEADERSAPIUSER["x-citytaxi-application-id"] = "citytaxi";

export default class Base {
  static async get(path, api = "server") {
    let url = `${REACT_APP_SERVER}${path}`;
    if (api === "geo") {
      url = `${REACT_APP_API_GEO}${path}`;
    } else if (api === "taxia") {
      url = `${REACT_APP_API_TAXIA}${path}`;
    } else if (api === "user") {
      url = `${REACT_APP_API_USER}${path}`;
      return this.makeRequest("GET", url, null, HEADERSAPIUSER);
    } else if (api === "core") {
      url = `${REACT_APP_API_CORE}${path}`;
      return this.makeRequest("GET", url, null, HEADERSCORE);
    }
    return this.makeRequest("GET", url, null, HEADERS);
  }

  static async post(path, data, api = "server") {
    let url = `${REACT_APP_SERVER}${path}`;
    if (api === "geo") {
      url = `${REACT_APP_API_GEO}${path}`;
    } else if (api === "user") {
      url = `${REACT_APP_API_USER}${path}`;
      return this.makeRequest("POST", url, data, HEADERSAPIUSER);
    } else if (api === "taxia") {
      url = `${REACT_APP_API_TAXIA}${path}`;
    }
    return this.makeRequest("POST", url, data, HEADERS);
  }

  static async makeRequest(method, url, data = null, headers) {
    const options = { url, method, headers };
    if (data) {
      options.data = data;
    }
    return this.send(options);
  }

  static async send(options) {
    return axios(options)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        } else {
          return error;
        }
      });
  }
}
