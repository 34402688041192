import React from "react";
import { Switch } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Login from "./components/auth/login";
import Register from "./components/auth/register";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Request from "./components/request/index";
import Assigned from "./components/assigned/index";
import ChangeLanguage from "./components/shared/changeLanguage";
import PasswordRecovery from "./components/auth/passwordRecovery";
import "./App.scss";

const useStyles = makeStyles((theme) => ({
  typography: {
    fontFamily: ["Roboto"].join(","),
  },
  root: {
    display: "flex",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  // content: {
  //   flexGrow: 1,
  //   padding: theme.spacing(3),
  //   transition: theme.transitions.create("margin", {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.leavingScreen,
  //   }),
  //   // marginLeft: -drawerWidth,
  // },
  // contentShift: {
  //   transition: theme.transitions.create("margin", {
  //     easing: theme.transitions.easing.easeOut,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  //   marginLeft: 0,
  // },
}));

export default function App() {
  const classes = useStyles();
  return (
    <>
      <Switch>
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/register" component={Register} />
        <PublicRoute path="/recovery" component={PasswordRecovery} />
      </Switch>
      <div className={classes.root}>
        <CssBaseline />
        <ChangeLanguage />

        {/* <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        > */}
        {/* <div className={classes.drawerHeader} /> */}
        <Switch>
          <PrivateRoute path="/assigned" component={Assigned} />
          <PrivateRoute path="/" component={Request} />
        </Switch>
        {/* </main> */}
      </div>
    </>
  );
}
