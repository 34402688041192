/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import mapboxgl from "!mapbox-gl";
import io from "socket.io-client";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

import Service from "../../api/service";

import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import Header from "../shared/header";
import Box from "@mui/material/Box";
import polyline from "@mapbox/polyline";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import AssignedCard from "./assignedCard";
import CancelService from "./cancelService";
import CustomDialog from "../common/customDialog";

const { REACT_APP_MAPBOX_TOKEN } = process.env;
mapboxgl.accessToken = REACT_APP_MAPBOX_TOKEN;

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    bottom: 0,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute !important",
    backgroundColor: "#0000004d !important",
  },
  lateral: {
    [theme.breakpoints.down("md")]: {
      height: "125px",
    },
    [theme.breakpoints.up("md")]: {
      height: "100%",
    },
  },
  bottomzone: {
    position: "absolute",
    alignItems: "center",
    justifyItems: "center",
    justifyContent: "center",
    alignContent: " center",
    bottom: "10px",
    maxWidth: "90%",
    zIndex: theme.zIndex.drawer + 2,
  },
}));
let socketService;
let socketLocations;
let properties;

const options = {
  autoconnect: true,
  transports: ["websocket"],
  reconnect: true,
};

const Assigned = (props) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [zoom, setZoom] = useState(17);
  const [destinationMarker, setDestinationMarker] = useState(null);
  const [userMarker, setUserMarker] = useState(null);
  const [taxiMarker, setTaxiMarker] = useState(null);
  const [share, setShare] = useState();
  const [openBackdrop, setOpenBackdrop] = useState(true);
  const [openTimeOutPopup, setTimeOutPopup] = useState(false);
  const [service, setService] = useState(null);
  const [cancelbutton, setCancelButton] = useState(true);
  const [route, setRoute] = useState(null);
  const [message, setMessage] = useState({
    state: false,
    description: "",
    type: "success",
    event: "",
  });
  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const taxiRef = useRef();
  taxiRef.current = taxiMarker;

  useEffect(() => {
    properties = props.location.lat
      ? props
      : JSON.parse(localStorage.getItem("props"));
    if (properties === null) window.location.href = "/";

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [properties.location.long, properties.location.lat],
      zoom: zoom,
    });

    initDestinationMarker();
    initCarMarker();

    const el = document.createElement("div");
    el.className = "marker";
    const userMarker = new mapboxgl.Marker(el)
      .setLngLat([properties.location.long, properties.location.lat])
      .addTo(map.current)
      .setDraggable(false)
      .setPopup(
        new mapboxgl.Popup({
          offset: 25,
          closeButton: false,
          closeOnClick: false,
        }).setText(t("service.you"))
      );

    userMarker.setLngLat([properties.location.long, properties.location.lat]);
    map.current.flyTo({
      center: [properties.location.long, properties.location.lat],
      zoom: zoom,
    });

    map.current.addControl(new mapboxgl.NavigationControl(), "bottom-right");

    if (properties.location?.service) {
      localStorage.setItem("props", JSON.stringify(properties));
      localStorage.setItem(
        "service",
        JSON.stringify(properties.location.service.id)
      );

      const user = JSON.parse(localStorage.getItem("user-taxia"));
      joinRoomService("email", user.email);
      if (localStorage.getItem("route") !== null) {
        setRoute(JSON.parse(localStorage.getItem("route")));
      }
      if (localStorage.getItem("serviceData") !== null) {
        serviceEventListener(JSON.parse(localStorage.getItem("serviceData")));
      }
    } else {
      window.location.href = "/";
    }
    setUserMarker(userMarker);
  }, []);

  const getShare = async (service) => {
    const share = await Service.getURL(service);
    setShare(share.data);
  };

  const drawRoute = (coordinates) => {
    if (map.current.getLayer("route")) map.current.removeLayer("route");
    if (map.current.getSource("route")) map.current.removeSource("route");
    map.current.addSource("route", {
      type: "geojson",
      data: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: coordinates,
        },
      },
    });
    map.current.addLayer({
      id: "route",
      type: "line",
      source: "route",
      layout: {
        "line-join": "round",
        "line-cap": "round",
        "line-round-limit": 0.5,
      },
      paint: {
        "line-color": "#6738D1",
        "line-width": 10,
      },
    });
  };

  const decodeRoute = (route) => {
    const flippedLngLat = polyline
      .decode(route.route)
      .map(([lat, lng]) => [lng, lat]);
    drawRoute(flippedLngLat);
  };

  const arriveAlert = async () => {
    setMessage({
      state: true,
      description: "events.arrive",
      type: "success",
      event: "arrive",
    });
  };

  const finishAlert = () => {
    setMessage({
      state: true,
      description: "events.finish",
      type: "success",
      event: "finish",
    });
  };

  const cancelAlert = () => {
    setMessage({
      state: true,
      description: "events.cancel",
      type: "warning",
      event: "cancel",
    });
  };

  const closeSockets = () => {
    if (socketService) {
      socketService.disconnect();
      socketService.removeAllListeners("connect");
      socketService.removeAllListeners("response");
      socketService.removeAllListeners("service.notify.event");
    }
    if (socketLocations) {
      socketLocations.disconnect();
      socketLocations.removeAllListeners("connect");
      socketLocations.removeAllListeners("locations");
    }
  };

  const cancelService = async () => {
    closeSockets();
    const serviceid = localStorage.getItem("service");
    const data = { serviceId: serviceid, motiveId: 1 };
    await Service.cancel(data);

    history.push({
      pathname: "/",
    });
  };

  const serviceEventListener = (data) => {
    if (data.status === "ASSIGNED") {
      addMarkerTaxi(data.device.latitude, data.device.longitude);
      if (route) decodeRoute(route.route);
      joinRoomLocations(data.device.id, data.userEmail);
      localStorage.setItem("serviceData", JSON.stringify(data));
      setOpenBackdrop(false);
      setService(data);
      getShare(properties.location.service.id);
    }
    if (data.status === "EXCEEDED") {
      setTimeOutPopup(true);
    }
    if (data.status === "ARRIVAL") {
      arriveAlert();
    }
    if (data.status === "USER_ON_BOARD") {
      setCancelButton(false);
    }
    if (data.status === "USER_NOT_FOUND") {
      cancelAlert();
      cancelService();
    }
    if (data.status === "FINISHED") {
      finishAlert();
    }
  };

  const addMarkerTaxi = (lat, lng) => {
    try {
      taxiRef.current
        .setLngLat([lng, lat])
        .setPopup(
          new mapboxgl.Popup({
            offset: 25,
            closeButton: false,
            closeOnClick: false,
          }).setText(t("services.yourTaxi"))
        )
        .addTo(map.current);
    } catch (error) {
      setTimeout(() => {
        taxiRef.current
          .setLngLat([lng, lat])
          .setPopup(
            new mapboxgl.Popup({
              offset: 25,
              closeButton: false,
              closeOnClick: false,
            }).setText(t("services.yourTaxi"))
          )
          .addTo(map.current);
      }, 200);
      console.error("error con marcador de taxi", error);
    }

    map.current.fitBounds(
      [
        [properties.location.long, properties.location.lat],
        [lng, lat],
      ],
      {
        padding: { top: 250, bottom: 150, left: 150, right: 150 },
      }
    );
  };

  const joinRoomService = (type, userValue) => {
    const room = { email: userValue };
    socketService = io(process.env.REACT_APP_SERVER, options);
    socketService.removeAllListeners("service.notify.event");
    socketService.removeAllListeners("connect");
    socketService.on("connect", () => {
      socketService.emit("join_room", room);
    });
    socketService.on("service.notify.event", serviceEventListener);
    socketService.on("connect_error", () => {
      socketService.connect();
    });
  };

  const joinRoomLocations = (deviceId, userEmail) => {
    const room = {
      device_id: deviceId,
      email: userEmail,
    };
    socketLocations = io(process.env.REACT_APP_SOCKET_LOCATIONS, options);
    socketLocations.removeAllListeners("location");
    socketLocations.removeAllListeners("connect");
    socketLocations.on("connect", () => {
      socketLocations.emit("joinroom", room);
    });
    socketLocations.on("location", positionData);
    socketLocations.on("connect_error", () => {
      socketLocations.connect();
    });
  };

  const positionData = (data) => {
    taxiRef.current.setLngLat([data.longitude, data.latitude]);
  };

  function handleCloseDialogs() {
    setTimeOutPopup(false);
    cancelService();
  }

  function handleCloseSnackbar() {
    setMessage({
      state: false,
      description: "",
      type: "info",
      event: "",
    });
    if (message.event === "cancel" || message.event === "finish") {
      history.push({
        pathname: `/`,
      });
    }
  }

  const initDestinationMarker = () => {
    const iconDestination = document.createElement("div");
    iconDestination.className = "markerDestination";
    const destinationMarker = new mapboxgl.Marker(iconDestination);
    setDestinationMarker(destinationMarker);
  };

  const initCarMarker = () => {
    const iconCar = document.createElement("div");
    iconCar.className = "markerCar";
    const taxiMarker = new mapboxgl.Marker(iconCar);
    setTaxiMarker(taxiMarker);
  };

  return (
    <Grid container className={classes.root} rowSpacing={0} columnSpacing={0}>
      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        lg={2}
        xl={2}
        sx={{
          backgroundColor: theme.palette.primary.main,
        }}
        className={classes.lateral}
        component="section"
      >
        <Header />
      </Grid>
      <Grid
        component="section"
        item
        xs={12}
        sm={12}
        md={9}
        lg={10}
        xl={10}
        sx={{
          position: "relative",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          justifyContent: "center",
          height: {
            xs: "85%",
            sm: "85%",
            md: "100%",
          },
        }}
        container
      >
        <Box
          ref={mapContainer}
          sx={{
            position: "relative",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            width: "100%",
          }}
        />

        <Grid container className={classes.bottomzone} spacing={2}>
          {service ? (
            <Grid item xs={12} sm={7} md={6} lg={5} xl={4}>
              <AssignedCard service={service} route={route} />
            </Grid>
          ) : (
            ""
          )}
          {cancelbutton ? (
            <Grid item xs={12} sm={5} md={12} lg={6} xl={6}>
              <CancelService
                service={service}
                socketLocations={socketLocations}
                socketService={socketService}
                cancel={cancelService}
              />
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <Box
            className="ripple-loader"
            sx={{ zIndex: theme.zIndex.drawer + 3 }}
          >
            <div></div>
            <div></div>
          </Box>
        </Backdrop>
        <CustomDialog
          onClose={handleCloseDialogs}
          open={openTimeOutPopup}
          variant="full-dialog"
          title="Tiempo de espera agotado !!"
          severity="info"
        >
          <Box
            sx={{
              margin: "0px",
              padding: "0px",
              display: "flex",
              flexDirection: "column",
              maxWidth: "50wh",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h6"
                className={classes.text}
                sx={{
                  fontWeight: "500",
                  marginBottom: "50px",
                  marginTop: "30px",
                }}
              >
                {t("assigned.exceeded")}
              </Typography>
            </Box>
            <Box mt={12} sx={{ display: "flex", flexDirection: "column" }}>
              <Button
                type="submit"
                form="form-request"
                color="primary"
                variant="contained"
                fullWidth
                onClick={handleCloseDialogs}
                autoFocus
              >
                Ok
              </Button>
            </Box>
          </Box>
        </CustomDialog>
      </Grid>
      <CustomDialog
        onClose={handleCloseSnackbar}
        open={message.state}
        variant="snackbar"
        severity={message.type}
        title={
          message.description === "events.arrive"
            ? t(message.description)
            : null
        }
      >
        <Box
          sx={{
            margin: "0px",
            padding: "0px",
            display: "flex",
            flexDirection: "column",
            height: "150px",
          }}
        >
          {message.description === "events.arrive" ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h6"
                className={classes.text}
                sx={{
                  fontWeight: "500",
                  marginBottom: "50px",
                  marginTop: "30px",
                }}
              >
                {t("events.arriveText")}
              </Typography>
              <Link
                variant="h6"
                className={classes.text}
                sx={{
                  fontWeight: "500",
                }}
                href={share}
                target="_blank"
              >
                {"help.taxialife.info/share/..."}
              </Link>
            </Box>
          ) : (
            <Typography
              variant="subtitle1"
              className={classes.text}
              sx={{
                fontWeight: "500",
                marginBottom: "50px",
                marginTop: "30px",
              }}
            >
              {t(message.description)}
            </Typography>
          )}
        </Box>
      </CustomDialog>
    </Grid>
  );
};

Assigned.contextTypes = {
  router: PropTypes.object,
};

export default Assigned;
