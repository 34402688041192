import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@mui/styles";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  text: {
    marginX: "5px",
    textTransform: "uppercase",
    color: grey[700],
    fontWeight: "bold",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  textValue: {
    margin: "0px",
    textAlign: "center",
  },
}));
export default function RouteCard(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  useEffect(() => {});
  const theme = useTheme();

  return (
    <Card
      elevation={18}
      sx={{ margin: "auto", padding: "10px", width: "fit-content" }}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexDirection: "row",
          width: "fit-content",
        }}
      >
        <Box mx={1}>
          <Typography
            variant="subtitle2"
            className={classes.text}
            sx={{
              fontWeight: "700",
            }}
          >
            {t("service.distance")}
          </Typography>
          <p className={classes.textValue}>{`${Math.floor(
            props.route.distanceMeters
          )} m`}</p>
        </Box>
        <Box mx={1}>
          <Typography
            variant="subtitle2"
            className={classes.text}
            sx={{
              fontWeight: "700",
            }}
            mx={2}
          >
            {t("service.time")}
          </Typography>
          <p className={classes.textValue}>
            {`${props.route.duration} `} {t("service.minutes")}
          </p>
        </Box>
        <Box
          container
          direction="column"
          justifycontent="center"
          alignItems="center"
          mx={1}
        >
          <Typography
            variant="subtitle2"
            className={classes.text}
            sx={{
              fontWeight: "700",
            }}
          >
            {t("service.value")}
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.textValue}
            noWrap
            sx={{
              fontSize: {
                xs: "14px",
                sm: "14px",
                md: "16px",
                lg: "18px",
                xl: "20px",
              },
              color: theme.palette.primary.main,
              fontWeight: "bolder",
            }}
          >
            {` ${props.route.currencySymbol}${
              props.route.farewithCharges + props.route.defaultCharges
            } ${props.route.currencyCode}`}
          </Typography>
        </Box>
      </Grid>
    </Card>
  );
}
