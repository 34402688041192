import React from "react";
import { useTranslation } from "react-i18next";

import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Box from "@mui/system/Box";
import Typography from "@mui/material/Typography";
import { grey } from "@mui/material/colors";

import { makeStyles, useTheme } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
  },
  lateral: {
    width: "100%",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "11px",
    },
  },
  text: {
    marginX: "5px",
    textTransform: "uppercase",
    color: grey[700],
    fontWeight: "bold",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  textValue: {
    margin: "0px",
    textAlign: "center",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
  },
}));

export default function AssignedCard(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Card
      elevation={18}
      sx={{ margin: "auto", padding: "10px", width: "fit-content" }}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexDirection: "row",
          width: "fit-content",
        }}
      >
        <Box mx={1} className={classes.box}>
          <Avatar
            alt={props.service.driver.full_name}
            src={props.service.driver.photo}
            sx={{ width: 65, height: 65, marginX: "auto" }}
          />
          <Typography
            variant="subtitle2"
            className={classes.text}
            sx={{
              fontWeight: "700",
              fontSize: "12px",
              marginTop: "10px",
            }}
            mx={1}
          >
            {props.service.driver.full_name}
          </Typography>
        </Box>
        <Box mx={1} className={classes.box} pt={1}>
          <Typography
            variant="subtitle2"
            className={classes.text}
            sx={{
              fontWeight: "700",
            }}
            mx={2}
          >
            {`${t("assigned.plate")}:`}
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.textValue}
            noWrap
            sx={{
              fontSize: {
                xs: "14px",
                sm: "14px",
                md: "16px",
                lg: "18px",
                xl: "20px",
              },
              color: theme.palette.primary.main,
              fontWeight: "bolder",
            }}
          >
            {props.service.vehicle.license_plate}
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.text}
            sx={{
              fontWeight: "700",
              marginTop: "10px",
            }}
            mx={2}
          >
            {`${t("assigned.lateral")}:`}
          </Typography>
          <p className={classes.textValue}>
            {`${props.service.vehicle.vehicle_identification_number} `}
          </p>
        </Box>
        <Box mx={1} className={classes.box} pt={1}>
          <Typography
            variant="subtitle2"
            className={classes.text}
            sx={{
              fontWeight: "700",
            }}
          >
            {t("assigned.secCode")}
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.textValue}
            noWrap
            sx={{
              fontSize: {
                xs: "14px",
                sm: "14px",
                md: "16px",
                lg: "18px",
                xl: "20px",
              },
              color: theme.palette.green.main,
              fontWeight: "bolder",
            }}
          >
            {props.service.phone ? props.service.phone.slice(-2) : ""}
          </Typography>
          {props.route ? (
            <>
              <Typography
                variant="subtitle2"
                className={classes.text}
                sx={{
                  fontWeight: "700",
                  marginTop: "10px",
                }}
              >
                {t("service.value")}
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.textValue}
                noWrap
                sx={{
                  fontSize: {
                    xs: "14px",
                    sm: "14px",
                    md: "16px",
                    lg: "18px",
                    xl: "20px",
                  },
                  color: theme.palette.primary.main,
                  fontWeight: "bolder",
                }}
              >
                {`${props.route.currencySymbol} ${
                  props.route.farewithCharges + props.route.defaultCharges
                } ${props.route.currencyCode}`}
              </Typography>
            </>
          ) : (
            ""
          )}
        </Box>
      </Grid>
    </Card>
  );
}
