import { createTheme } from "@mui/material/styles";
// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#6738D1",
    },
    secondary: {
      main: "#F2F2F2",
    },
    error: {
      main: "#B80017",
    },
    background: {
      default: "#ffffff",
    },
    white: {
      main: "#FFFFFF",
    },
    black: {
      main: "#000000",
    },
    green: {
      main: "#6BBF00",
    },
    avatar: {
      main: "#bdbdbd",
    },
  },
  typography: {
    fontFamily: ["Poppins"].join(","),
    button: {
      fontSize: "18px",
    },
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: "20px",
          backgroundColor: "#F2F2F2",
          height: "70px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "20px",
          color: "#fff",
          height: "70px",
          marginTop: "15px",
        },
        contained: {
          textTransform: "capitalize",
        },
        containedPrimary: {
          backgroundColor: "#6738D1",
        },
        containedSecondary: {
          backgroundColor: "#000",
        },
        text: {
          height: "35px",
          textTransform: "capitalize",
          marginTop: "0px",
        },
        textPrimary: {
          color: "#6738D1",
          backgroundColor: "#D3C5F0",
        },
        textSecondary: {
          color: "#FFFFFF",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          fontSize: "16px",
          fontFamily: "Poppins",
        },
      },
    },
    MuiDialog: {
      variants: [
        {
          props: { variant: "snackbar" },
        },
        {
          props: { variant: "full-dialog" },
        },
      ],
      styleOverrides: {
        container: ({ ownerState }) => ({
          ...((ownerState.variant === "snackbar" && {
            alignItems: "end",
          }) ||
            (ownerState.variant === "full-dialog" && {
              alignItems: "end",
            })),
        }),
        paper: ({ ownerState }) => ({
          ...((ownerState.variant === "snackbar" && {
            borderTopLeftRadius: "25px",
            borderTopRightRadius: "25px",
            minHeight: "50px",
            marginBottom: "0px",
          }) ||
            (ownerState.variant === "full-dialog" && {
              borderTopLeftRadius: "25px",
              borderTopRightRadius: "25px",
              minHeight: "60%",
              marginBottom: "0px",
            }) ||
            (ownerState.variant === "dialog" && {
              borderRadius: "25px",
              height: "150px",
            })),
        }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...((ownerState.severity === "error" && {
            backgroundColor: "#ffe1d0",
          }) ||
            (ownerState.severity === "success" && {
              backgroundColor: "#eef8e5",
            }) ||
            (ownerState.severity === "warning" && {
              backgroundColor: "#FFF4E5",
            }) ||
            (ownerState.severity === "info" && {
              backgroundColor: "#E5F6FD",
            })),
        }),
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...((ownerState.severity === "error" && {
            color: "#5F2120",
            textAlign: "center",
          }) ||
            (ownerState.severity === "warning" && {
              color: "#bfb200",
              textAlign: "center",
            }) ||
            (ownerState.severity === "info" && {
              color: "#006cbf",
              textAlign: "center",
            }) ||
            (ownerState.severity === "success" &&
              ((ownerState.variant === "dialog" && {
                color: "#6BBF00",
                textAlign: "center",
                paddingTop: "30px",
              }) || {
                color: "#6BBF00",
                textAlign: "center",
              }))),
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          textAlign: "center",
          textTransform: "capitalize",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          margin: "20px auto 0 auto",
          borderRadius: "20px",
          backgroundColor: "#FFFFFF",
          padding: "20px",
          height: "fit-content",
        },
      },
    },
    MuiInputBase: {
      variants: [
        {
          props: { variant: "custom-card-input" },
        },
      ],
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "custom-card-input" && {
            fontWeight: "500",
            border: "2px solid #e0e0e0",
            paddingLeft: "7px",
            marginLeft: "0px",
            lineHeight: "1.2em",
            borderRadius: "5px",
            "&.Mui-focused": {
              border: "2px solid #6738D1",
            },
          }),
        }),
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
      },
    },
  },
});

export default theme;
