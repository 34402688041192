// LOGIN
// import {useContext } from "react";
import jwtDecode from "jwt-decode";
// import { CustomThemeContext } from "../themes/CustomThemeProvider";
export const login = (email, password) => {
  if (email && password) {
    // User.login({ email, password }).then((data) => {
    //   localStorage.setItem("auth", d);
    //   props.history.push("/request");
    // });
  } else {
    // message.error("Login Failed");
  }
};

// LOGOUT
export const logout = () => {
  localStorage.removeItem("auth");
  localStorage.removeItem("user-taxia");
  localStorage.removeItem("service");
  window.location.href = `/login`;
};

// LOGIN STATUS
export const isLogin = () => {
  if (localStorage.getItem("auth")) {
    const decoded = jwtDecode(localStorage.getItem("auth"));
    if (new Date(decoded.exp * 1000) > new Date()) {
      return true;
    }
    return false;
  }
  // if (localStorage.getItem("tpagaUserId")) {
  //   return true;
  // }
  return false;
};
