import Base from "./base";

async function getAddress(cityId, latitude, longitude) {
  const response = await Base.post(
    "/near",
    { city: cityId, latitude, longitude },
    "geo"
  );
  return response.data;
}

async function search(cityId, address) {
  const response = await Base.post("/search", { city: cityId, address }, "geo");
  return response.data;
}

async function domain(latitude, longitude) {
  const response = await Base.get(
    `/domain/availability/${latitude}/${longitude}`,
    "core"
  );
  if (response.errors) {
    return response;
  } else {
    return response.data;
  }
}

async function getLocation(ip) {
  const response = await Base.makeRequest(
    "GET",
    `https://ipxapi.com/api/ip?ip=${ip}`,
    null,
    {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer 4245|AciCI4HUKakHm7M1ihrdqvLEgPLJuIfdQNePeUty",
      "cache-control": "no-cache",
    }
  );
  return response.data;
}

export default {
  getAddress,
  search,
  domain,
  getLocation,
};
