import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import logo from "../../assets/img/taxia-life-logo-white.png";

import User from "../../api/user";
import CustomDialog from "../common/customDialog";

import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";

// makestyles, being a legacy element, will only be used
// for configurations related to responsive breakpoints,
// positions, spacing and dimensions of the elements
const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    bottom: 0,
  },
  paper: {
    margin: theme.spacing(4, 4),
    height: "100%",
    width: "30%",
  },
  form: {
    marginTop: theme.spacing(1),
  },
  logo: {
    marginBottom: "10vh",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.up("md")]: {
      width: "40%",
    },
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
  },
}));

const PasswordRecovery = (props) => {
  const history = useHistory();

  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState({
    state: false,
    description: "",
    type: "success",
  });
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    localStorage.removeItem("auth");
    localStorage.removeItem("user-taxia");
    localStorage.removeItem("service");
  });

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const response = await User.recovery({ email });

    if (response?.message) {
      setMessage({
        state: true,
        description: `${t("recovery.error")}`,
        type: "error",
      });
      handleOpen();
      return;
    }
    if (response?.status === 200 && response?.data === null) {
      setMessage({
        state: true,
        description: `${t("recovery.success")} ${email}`,
        type: "success",
      });
      setTimeout(() => {
        window.location.href = "/login";
      }, 10000);
      handleOpen();
    }
  };

  const handleOpen = () => {
    setOpen(true);
    setTimeout(() => {
      handleClose();
    }, 10000);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container component="main" className={classes.root}>
      <Grid
        item
        sm={5}
        md={4}
        sx={{ backgroundColor: theme.palette.primary.main }}
        component="section"
      >
        <Box
          height="100%"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={logo} className={classes.logo} alt="taxia-logo" />
        </Box>
      </Grid>
      <Grid
        component="section"
        item
        xs={12}
        sm={7}
        md={8}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          className={classes.paper}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              color: "black",
              fontWeight: "500",
              fontSize: "42px",
            }}
          >
            {t("recovery.header")}
          </Typography>
          <Typography
            variant="h3"
            sx={{
              color: "black",
              fontWeight: "400",
              fontSize: "16px",
              marginTop: "30px",
            }}
          >
            {t("recovery.subHeader")}
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              margin="normal"
              required
              type="email"
              fullWidth
              id="email"
              label={t("login.email")}
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="filled"
              autoComplete="email"
              InputProps={{ disableUnderline: true }}
            />

            <Button type="submit" fullWidth variant="contained" color="primary">
              {open ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{
                    marginRight: "10px",
                  }}
                />
              ) : (
                ""
              )}
              {t("login.button")}
            </Button>

            <Grid
              container
              direction="column"
              justifyContent="space-around"
              mt={2}
            >
              <Grid item>
                <Link
                  onClick={() =>
                    history.push({
                      pathname: "/register",
                    })
                  }
                  variant="subtitle1"
                  underline="none"
                >
                  {t("login.account")}
                </Link>
              </Grid>
            </Grid>
          </form>
          <Box mt={17}>
            <Typography
              style={{
                color: theme.palette.primary.main,
              }}
            >
              {"Copyright© "}
              <Link href="https://taxialife.com/" underline="hover">
                Taxia Life {new Date().getFullYear()}
                {"."}
              </Link>
            </Typography>
          </Box>
        </Box>
      </Grid>
      <CustomDialog
        onClose={handleClose}
        open={open}
        variant={message.type === "error" ? "snackbar" : "dialog"}
        severity={message.type}
      >
        {message.description}
      </CustomDialog>
    </Grid>
  );
};

export default PasswordRecovery;
