import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import en from "../../assets/img/us.png";
import es from "../../assets/img/colombia.png";
// import taxiaIcon from "../../assets/img/taxia-life-logo.png";
// import darkIcon from "../../assets/img/dark.png";
// import claroIcon from "../../assets/img/Claro-Logo.png";
// import { CustomThemeContext } from "../../themes/CustomThemeProvider";

const changeLanguage = () => {
  const { i18n } = useTranslation();

  const [language, setLanguage] = useState(
    localStorage.getItem("languageInit") || "es"
  );
  const [anchorElLang, setAnchorElLang] = useState(null);
  // const [anchorElTheme,setAnchorElTheme] = useState(null);

  const langShow = {
    es: es,
    en: en,
  };

  // const { currentTheme, setTheme } = useContext(CustomThemeContext);

  const handleClick = (event) => {
    setAnchorElLang(event.currentTarget);
  };

  // const handleThemeClick = (event) => {
  //   setAnchorElTheme(event.currentTarget);
  // };

  const CloseLangMenu = () => {
    setAnchorElLang(null);
  };

  // const CloseThemeMenu = () => {
  //   setAnchorElTheme(null);
  // };

  const handleLangChange = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem("languageInit", lang);
    CloseLangMenu();
  };

  // const handleThemeChange = (theme) => {
  //   setTheme(theme);
  // };

  return (
    <div
      style={{
        position: "fixed",
        top: "13px",
        right: "35px",
        zIndex: "200",
      }}
    >
      {/* <IconButton
        style={{ backgroundColor: "#f1f1f1", marginRight: "10px" }}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleThemeClick}
      >
        {
          <>
            <img
              src={
                currentTheme === "claro"
                  ? claroIcon
                  : currentTheme === "dark"
                  ? darkIcon
                  : taxiaIcon
              }
              width="20px"
              height="20px"
              alt="taxia-logo"
            />
          </>
        }
      </IconButton>

      <Menu
        anchorEl={anchorElTheme}
        keepMounted
        open={Boolean(anchorElTheme)}
        onClose={CloseThemeMenu}
      >
        <MenuItem onClick={() => handleThemeChange("taxia")}>
          <img src={taxiaIcon} width="30px" alt="taxia-logo" />
        </MenuItem>
        <MenuItem onClick={() => handleThemeChange("claro")}>
          <img src={claroIcon} width="30px" alt="taxia-logo" />
        </MenuItem>
        <MenuItem onClick={() => handleThemeChange("dark")}>
          <img src={darkIcon} width="30px" alt="taxia-logo" />
        </MenuItem>
      </Menu> */}

      <IconButton
        style={{ backgroundColor: "#f1f1f1" }}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {
          <>
            <img src={langShow[language]} width="20px" alt="taxia-logo" />
          </>
        }
      </IconButton>
      <Menu
        anchorEl={anchorElLang}
        keepMounted
        open={Boolean(anchorElLang)}
        onClose={CloseLangMenu}
      >
        <MenuItem onClick={() => handleLangChange("es")}>
          <img src={es} width="30px" alt="taxia-logo" />
        </MenuItem>
        <MenuItem onClick={() => handleLangChange("en")}>
          <img src={en} width="30px" alt="taxia-logo" />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default changeLanguage;
