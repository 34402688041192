import React from "react";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function MenuAppBar({ closeSesion }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const useStyles = makeStyles((theme) => ({
    avatar: {
      backgroundColor: theme.palette.avatar.main,
    },
  }));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();
  let userName = null;
  if (localStorage.getItem("user-taxia") && localStorage.getItem("auth")) {
    userName = JSON.parse(localStorage.getItem("user-taxia"));
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        disableRipple
      >
        <Avatar
          className={classes.avatar}
          alt={userName ? userName.name : ""}
          src={userName ? userName.photo : ""}
          sx={{
            width: { xs: 45, sm: 45, md: 45, lg: 80, xl: 80 },
            height: { xs: 45, sm: 45, md: 45, lg: 80, xl: 80 },
          }}
        />
      </IconButton>

      {useMediaQuery(theme.breakpoints.down("md")) ? (
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={closeSesion}>{t("user.closeSession")}</MenuItem>
        </Menu>
      ) : (
        ""
      )}
      {/* <MenuItem onClick={handleClose}>{t("user.profile")}</MenuItem> */}

      <Typography
        variant="subtitle1"
        sx={{
          color: theme.palette.white.main,
          fontWeight: "500",
          fontSize: { sm: "16px", md: "16px", lg: "20px" },
        }}
      >
        {userName.name}
      </Typography>
    </Box>
  );
}
