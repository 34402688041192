import { forwardRef } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";

import conditionalProp from "../../conditionalProp";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import Divider from "@mui/material/Divider";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CustomDialog(props) {
  const { onClose, open, children, variant, title, severity } = props;
  const theme = useTheme();
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{
        sx: {
          [theme.breakpoints.up("lg")]: {
            width: "30% ",
          },
          [theme.breakpoints.between("md", "lg")]: {
            width: "50% ",
          },
          [theme.breakpoints.down("md")]: {
            width: "64% ",
          },
          [theme.breakpoints.down("xs")]: {
            width: "100% ",
          },
        },
      }}
      aria-describedby="alert-dialog-slide-description"
      // the variant prop indicates the custom type of dialog for the component's needs
      variant={variant}
    >
      {title ? <DialogTitle>{title}</DialogTitle> : ""}
      {variant === "full-dialog" && title ? <Divider variant="middle" /> : ""}

      <DialogContent severity={severity}>
        {variant === "full-dialog" ? (
          <>{children}</>
        ) : (
          <DialogContentText
            id="alert-dialog-slide-description"
            severity={severity}
            variant={variant}
          >
            {children}
          </DialogContentText>
        )}
      </DialogContent>
    </Dialog>
  );
}
CustomDialog.propTypes = {
  variant: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string,
  severity: conditionalProp("variant", PropTypes.string.isRequired),
};

export default CustomDialog;
