export const TRANSLATIONS_ES = {
  welcome: "Bienvenido",
  login: {
    header: "Iniciar sesión",
    subHeader: "Inicia sesión en tu cuenta de Taxia Life",
    email: "Dirección de correo electrónico",
    password: "Contraseña",
    button: "Ingresar",
    rememberData: "Recordarme",
    rememberPassword: "Recordar Contraseña",
    account: "¿No tengo una cuenta? Registrarme",
    error: "Error porfavor compruebe los datos Ingresados!",
    success: "Los datos fueron ingresados correctamente!",
  },
  register: {
    header: "Registro",
    tabData: "Con Email",
    tabPhone: " Con Teléfono",
    email: "Dirección de correo electrónico",
    password: "Contraseña",
    name: "Nombre",
    phone: "Teléfono",
    button: "Crear",
    buttonVerify: "Verificar",
    haveAccount: "¿Tienes una Cuenta? Inicia sesión",
    errorPhone: "Ingrese un telefono valido!",
    error: "Error porfavor compruebe los datos Ingresados!",
    success: "Los datos fueron ingresados correctamente!",
  },
  recovery: {
    header: "Recuperar Contraseña",
    subHeader:
      "Te enviaremos un enlace a tu correo para que puedas cambiar la contraseña.",
    success:
      "Hemos enviado un correo con los pasos para recuperar tu contraseña a",
    error: "El correo electrónico no existe.",
  },
  service: {
    you: "Tú",
    yourDestination: "Tu Destino",
    yourTaxi: "Tu vehículo",
    hi: "Hola",
    address: "TU DIRECCIÓN: ",
    to: "Hacia",
    pickup: "Donde te recogemos",
    destinationOptional: "Destino (Opcional)",
    destination: "Destino ",
    button: "Pedir Servicio",
    payService: "Pagar Servicio",
    paymentConfirmation: "Confirmación de pago",
    confirmationMessage:
      "Se procederá con el pago del servicio asignado, Desea continuar?",
    amount: "El servicio tiene un costo de: ",
    hint: "Pista",
    hintMessage: "Agrega una pista, para que nuestro taxista te ubique mejor?",
    cancel: "Cancelar",
    request: "Solicitar vehículo",
    continue: "Continuar",
    promoCodeCheck: "Tienes un codigo promocional?",
    voucherCheck: "Quieres un servicio con vale?",
    close: "Cerrar",
    voucher: "Vale",
    voucherReason: "Ingresa el motivo de uso",
    promoCode: "Codigo promocional",
    tip: "Propina",
    paymentMethod: "Metodo de pago",
    cash: "Efectivo",
    card: "Tarjeta",
    addCard: "Adicionar tarjeta",
    qr: "QR Bancolombia",
    preferences: "Preferencias del vehiculo",
    trunk: "Baul Grande",
    windows: "Vidrios electricos",
    air: "Aire acondicionado",
    pets: "Transportar mascotas",
    bike: "Transportar bicicleta",
    Wheelchair: "Silla de ruedas",
    occasionalTrip: "Viaje ocasional",
    mechanical: "Mecanico",
    guide: "Guia turistico",
    delivery: "Servicio de entrega",
    bilingual: "Conductor bilingüe",
    chosen: "Conductor elegido",
    male: "Quiero un Taxia Driver",
    female: "Quiero una Taxia Driver",
    modelFilter: "Filtrar vehiculo por modelo",
    all: "Todos",
    ratingFilter: "Filtrar conductor por calificacion",
    hourly: "Servicio por horas",
    hour: "hora",
    hours: "horas",
    rateDescriptionTitle: "Descripcion de la tarifa",
    distance: "Distancia",
    value: "Valor",
    time: "Tiempo estimado",
    minutes: "Minutos",
    nooptions: "Sin sugerencias",
  },
  assigned: {
    raiting: "Calificacion",
    cancel: "Cancelar Servicio",
    cancelHeader: "Esta seguro de cancelar el Servicio?",
    continue: "No",
    cancelService: "Si",
    lateral: "Lateral",
    plate: "Placa",
    vehicle: "Vehiculo",
    secCode: "Codigo de seguridad",
    chash: "efectivo",
    exceeded:
      "El tiempo de espera para tomar el servicio ha terminado. Será enviado al inicio.",
  },
  request: {
    previous:
      "Si tiene servicios pagados sin finalizar estos se cancelarán y se reembolsarán.",
  },
  user: {
    profile: "Perfil",
    closeSession: "Cerrar Sesion",
  },
  events: {
    arrive: "EL CONDUCTOR HA LLEGADO.",
    finish: "EL SERVICIO HA FINALIZADO. \nGRACIAS POR CONFIAR EN NOSOTROS",
    cancel:
      "SE CANCELARÁ EL SERVICIO. \nSI SU SERVICIO YA FUE PAGADO \nPRONTO RECIBIRÁ EL RESPECTIVO REEMBOLSO",
    error: "SE HA PRODUCIDO UN ERROR, SE CANCELARÁ EL SERVICIO",
    arriveText: "Puedes seguir el servicio en el siguiente enlace.",
  },
  error: {
    getCity: "Error obteniendo id de ciudad",
  },
  termsConditions: {
    title:
      "Aceptas Nuestros Términos & Condiciones y Autorizacion de datos personales",
    text: "Aqui ponemos a tu alcance los terminos y condiciones de nuestros servicios.",
    viewBtn: "Nuestros terminos",
    agreeBtn: "Aceptar y continuar",
  },
};
