import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import InputLabel from "@mui/material/InputLabel";

const useStyles = makeStyles((theme) => ({
  buttonClass: {
    backgroundColor: "red",
    "&.react-tel-input .flag-dropdown.open .selected-flag": {
      background: "#000",
    },
  },
  inputClass: {
    "&.react-tel-input .form-control:focus": {
      backgroundColor: "#E8E8E8",
      boxShadow: "0px",
      border: "none",
    },
  },
  label: {
    marginTop: "16px",
    marginBottom: "0px",
    marginLeft: "12px",
  },
}));

const CustomPhoneInput = ({ id, name, onChange, value, label }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <InputLabel htmlFor="verify-phone" className={classes.label}>
        {label}
      </InputLabel>
      <PhoneInput
        required
        id={id}
        country="co"
        countryCodeEditable={false}
        searchClass="search-class"
        inputExtraProps={{
          name: { name },
          required: true,
        }}
        value={value}
        onChange={onChange}
        onlyCountries={[
          "aw",
          "ar",
          "co",
          "it",
          "py",
          "pe",
          "us",
          "mx",
          "pa",
          "ec",
        ]}
        preferredCountries={["co"]}
        placeholder={t("register.phone")}
        autoFormat
        containerStyle={{
          height: "70px",
          backgroundColor: "transparent",
          marginBottom: "8px",
          border: "none",
        }}
        inputStyle={{
          width: "100%",
          height: "100%",
          backgroundColor: "#F2F2F2",
          borderRadius: "20px",
          border: "none",
          fontFamily: "Poppins",
          fontSize: "16px",
        }}
        buttonStyle={{
          height: "100%",
          borderTopLeftRadius: "20px",
          borderBottomLeftRadius: "20px",
          borderTop: "none",
          borderBottom: "none",
          borderLeft: "none",
          borderRight: "2px solid #BAB8B8",
        }}
        buttonClass={classes.buttonClass}
        InputClass={classes.inputClass}
      />
    </>
  );
};

export default CustomPhoneInput;
