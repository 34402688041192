import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CustomDialog from "../common/customDialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

const useStyles = makeStyles((theme) => ({
  input: {
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      width: "130px",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(1),
    maxWidth: "350px",
    width: "100%",
  },
  formControl: {
    marginTop: theme.spacing(2),
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  typography: {
    paddingTop: "10px",
  },
  formGroup: {
    display: "flex",
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  iconButton: {
    width: "50px",
    height: "50px",
  },
  textField: {
    height: "50px",
    "& .MuiFilledInput-root": {
      borderRadius: "10px",
    },
    "& .MuiFilledInput-input": {
      paddingTop: "0px ",
      paddingBottom: "0px",
      fontWeight: "500",
    },
  },
}));

export default function requestTaxi(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [hint, setHint] = useState("");
  const [open, setOpen] = useState(false);
  const [tips, setTips] = useState(0);
  const theme = useTheme();
  const user = useState(JSON.parse(localStorage.getItem("user-taxia")));

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      hint: hint,
      tip: tips,
    };
    props.request(data);
    setOpen(false);
  };

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={handleClickOpen}
        fullWidth
        sx={{
          marginTop: "0px",
          boxShadow: 18,
        }}
      >
        {t("service.request")}
      </Button>
      <CustomDialog
        onClose={handleClose}
        open={open}
        variant="full-dialog"
        title={t("service.button")}
      >
        <Box
          sx={{
            margin: "0px",
            padding: "0px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                color: theme.palette.primary.main,
                fontWeight: "700",
                fontSize: "20px",
              }}
            >
              {`${t("service.hi")} ${user[0].name.split(" ")[0]}`}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: theme.palette.black.main,
                fontWeight: "500",
                fontSize: "16px",
                marginTop: "10px",
                textAlign: "center",
                // width: '400px'
              }}
            >
              {t("service.hintMessage")}
            </Typography>

            <form
              id="form-request"
              onSubmit={handleSubmit}
              className={classes.form}
            >
              <FormGroup
                className={classes.formGroup}
                sx={{
                  justifyContent: "center",
                }}
              >
                <TextField
                  margin="normal"
                  placeholder={t("service.hint")}
                  required
                  type="text"
                  fullWidth
                  id="hint"
                  name="hint"
                  size="small"
                  variant="filled"
                  value={hint}
                  onChange={(e) => setHint(e.target.value)}
                  InputProps={{ disableUnderline: true }}
                  className={classes.textField}
                />
                <Divider sx={{ marginY: "10px" }} />
              </FormGroup>

              <FormGroup row className={classes.formGroup}>
                <Box sx={{ width: "100%" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: theme.palette.black.main,
                      fontWeight: "500",
                      fontSize: "16px",
                      textAlign: "left",
                    }}
                  >
                    {t("service.tip")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "left",
                    alignContent: "center",
                  }}
                >
                  <IconButton
                    color="black"
                    className={classes.iconButton}
                    aria-label="decrease"
                    onClick={(e) => setTips(tips >= 100 ? tips - 100 : 0)}
                    sx={{ boxShadow: 3 }}
                  >
                    <RemoveIcon />
                  </IconButton>
                  <TextField
                    type="number"
                    id="tips"
                    inputProps={{ "aria-label": "tips-input" }}
                    size="small"
                    value={tips}
                    variant="filled"
                    fullWidth
                    className={classes.textField}
                    InputProps={{ disableUnderline: true }}
                    sx={{
                      maxWidth: "40%",
                      marginX: "25px",
                    }}
                  />
                  <IconButton
                    color="black"
                    className={classes.iconButton}
                    aria-label="increase"
                    onClick={(e) => setTips(tips + 100)}
                    sx={{ boxShadow: 3, fontWeight: "bold" }}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
              </FormGroup>
            </form>
          </Box>
          <Box mt={6} sx={{ display: "flex", flexDirection: "column" }}>
            <Button
              type="submit"
              form="form-request"
              color="primary"
              variant="contained"
              fullWidth
            >
              {t("service.request")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClose}
              fullWidth
            >
              {t("service.cancel")}
            </Button>
          </Box>
        </Box>
      </CustomDialog>
    </>
  );
}
