import taxia from "./taxia";
import claro from "./claro";

const themes = {
  taxia,
  claro,
};

export default function getTheme(theme) {
  return themes[theme];
}
