export const TRANSLATIONS_EN = {
  welcome: "Welcome",
  login: {
    header: "Log In",
    subHeader: "Sign in to your Taxia Life account",
    email: "email",
    password: "password",
    button: "Sign In",
    rememberData: "Remember me",
    rememberPassword: "Forgot password?",
    account: "Don't have an account? Sign Up",
    error: "Error please check the entered data!",
    success: "The data was entered correctly!",
  },
  register: {
    header: "Sign Up",
    tabData: "With Email",
    tabPhone: "With Phone",
    email: "Email address",
    password: "Password",
    name: "Name",
    phone: "Phone",
    button: "Create",
    buttonVerify: "Verify",
    haveAccount: "Do you have an Account? Sign in",
    errorPhone: "Please enter a valid phone number!",
    error: "Error please check the entered data!",
    success: "The data was entered correctly!",
  },
  recovery: {
    header: "Password Recovery",
    subHeader:
      "We will send you a link to your email so you can change your password.",
    success: "We have sent an email with the steps to recover your password to",
    error: "The email does not exists.",
  },
  service: {
    you: "You",
    yourDestination: "Your Destination",
    yourTaxi: "Your vehicle",
    hi: "Hi",
    address: "YOUR ADDRESS: ",
    to: "To",
    pickup: "Pick Up",
    destinationOptional: "Destination (Optional)",
    destination: "Destination ",
    button: "Request Service",
    payService: "Pay Service",
    paymentConfirmation: "Payment Confirmation",
    confirmationMessage:
      "We will proceed with the payment of the assigned service, you wish to continue?",
    amount: "The service has a value of: ",
    hint: "Hint",
    hintMessage: "Add a hint, so that our taxi driver can better locate you?",
    cancel: "Cancel",
    request: "Request vehicle",
    continue: "Continue",
    promoCodeCheck: "Do you have a promotional code?",
    voucherCheck: "Do you want a service with a voucher?",
    close: "Close",
    voucher: "Voucher",
    voucherReason: "Enter the reason for using the voucher",
    promoCode: "Promotional codes",
    tip: "Tip ",
    paymentMethod: "Payment Method",
    cash: "Cash",
    card: "Card",
    addCard: "Add Card",
    qr: "Bancolombia QR Code",
    preferences: "Vehicle preferences",
    trunk: "Big trunk",
    windows: "Electric windows",
    air: "Air-conditioning",
    pets: "Transport pets",
    bike: "Transport bike",
    Wheelchair: "Whellchair",
    occasionalTrip: "Occasional trip",
    mechanical: "Mechanical",
    guide: "Tour guide",
    delivery: "Delivery service",
    bilingual: "Bilingual driver",
    chosen: "Chosen driver",
    male: "Male Taxia driver",
    female: "Female Taxia driver",
    modelFilter: "Filter vehicle by model",
    all: "All",
    ratingFilter: "Filter driver by rating",
    hourly: "Hourly service",
    hour: "hour",
    hours: "hours",
    rateDescriptionTitle: "Rate Description",
    distance: "Distance",
    value: "Value",
    time: "Estimated time",
    minutes: "Minutes",
    nooptions: "No suggestions",
  },
  assigned: {
    raiting: "Rating",
    cancel: "Cancel Service",
    cancelHeader: "Are you sure to cancel the Service?",
    continue: "No",
    cancelService: "Yes",
    lateral: "Lateral",
    plate: "Plate",
    vehicle: "Taxi",
    secCode: "Securty Code",
    chash: "Cash",
    exceeded:
      "The waiting time to take the service has ended. Will be sent to start",
  },
  request: {
    previous:
      "There is a previous unfinished service, it will be canceled and refunded.",
  },
  user: {
    profile: "Profile",
    closeSession: "Logout",
  },
  events: {
    arrive: "DRIVER HAS ARRIVED.",
    finish: "THE SERVICE HAS ENDED. \nTHANK YOU FOR TRUST US",
    cancel:
      "THE SERVICE WILL BE CANCELED. \nIF YOUR SERVICE HAS ALREADY BEEN PAID \nYOU WILL RECEIVE THE RESPECTIVE REFUND SOON",
    error: "AN ERROR HAS OCCURRED, THE SERVICE WILL BE TERMINATED",
    arriveText: "You can follow the service in the following link.",
  },
  error: {
    getCity: "Error getting city id",
  },
  termsConditions: {
    title:
      "Do you accept our Terms & Conditions and Authorization of personal data",
    text: "Here you have the terms and conditions of the service that we offer you.",
    viewBtn: "Our terms",
    agreeBtn: "Agree and continue",
  },
};
