import Base from "./base";

async function create(data) {
  const response = await Base.post("/user", data);
  return response;
}

async function login(data) {
  const response = await Base.post("/user/login", data);
  return response.data;
}

async function recovery(data) {
  const response = await Base.post("/rememberpassword", data, "user");
  return response;
}

export default {
  create,
  login,
  recovery,
};
